import { verifyTencentImageCode } from '@/api/api'
import '@/common/Tencent/TCaptcha.js'

export function getmaterial(table, tableData, page, pageSize, total) {
  let a = (pageSize * (page - 1))
  let b = (pageSize * page)
  if(table.length === undefined) { // 此币安接口根据名称查询的时候返回对象而不是数组，根据长度判断为数组还是对象，为对象的时候调slice方法会报错
    tableData = [table].slice(a, b)
    total = 1
  } else {
    tableData = table.slice(a, b)
    total = table.length
  }
  return { data: tableData, total: total }
}

// 验证手机号码  只能为数字 长度不能超过20
export function checkPhone(value) {
  var phone = /^[0-9]+$/
  if (!phone.test(value)) {
    return false
  }
  if (value.length > 20) {
    return false
  }
  if (value === '') {
    return false
  }
  return true
}
// 验证邮箱
export function checkEmail(value) {
  var email = /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/
  if (!email.test(value)) {
    return false
  }
  if (value === '') {
    return false
  }
  return true
}

// 验证密码
export function checkPassWord(value) {
  //必须为字母加数字加特殊符号且长度为6-16位
    // var password = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{6,16}$/
    // if (!password.test(value)) {
    //   return false
    // }
    // if (value === '') {
    //   return false
    // }
    if(value.length < 8 || value.length > 20) {
      return false
    }
    // 去掉验证 注释掉 怕又要加回来
    return true
}

// 验证钓鱼码
export function checkFishing(value) {
  // 不能包含特殊字符
  var fishing = /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/
  if (value === '') {
    return 1
  }
  if (!fishing.test(value)) {
    return 2
  }
  if(value.length < 4 || value.length > 20) {
    return 3
  }
  return true
}
//保留n位不四舍五入
export function toFixedDigit(num,n){
  if(typeof num != 'number'){
      return false;
  };
  if(n <= 0){//不保留小数，取整
      return num | 0;
  };
  num = num.toString();
  var result = "";
  var zeroResult = function(n){
      var zero = "";
      for(var i = 0;i<n;i++){
          zero+="0";
      }
      return zero;
  }
  if(num%1 == 0){//整数
      result = num + "."+zeroResult(n);
  }else{//小数
      var num1 = num.split(".");
      if(num1[1].length<n){
         result = num1[0]+"."+num1[1]+zeroResult(n-num1[1].length)
      }else{
         result = num1[0]+"."+num1[1].substring(0,n)
      }
  }
  return result;
}

export const phoneCode = [
  {"value":"+852","label":"中国香港"},
  {"value":"+86","label":"中国"},
  {"value":"+853","label":"中国澳门"},
  {"value":"+886","label":"中国台湾"},
  {"value":"+33","label":"法国"},
  {"value":"+82","label":"韩国"},
  {"value":"+1","label":"美国"},
  {"value":"+44","label":"英国"},
  {"value":"+65","label":"新加坡"},
  {"value":"+34","label":"西班牙"},
  {"value":"+54","label":"阿根廷"},
  {"value":"+49","label":"德国"},
  {"value":"+81","label":"日本"},
  {"value":"+66","label":"泰国"},
  {"value":"+84","label":"越南"},
  {"value":"+95","label":"缅甸"},
  {"value":"+39","label":"意大利"},
  {"value":"+351","label":"葡萄牙"},
  {"value":"+91","label":"印度"},
  {"value":"+1684","label":"美属萨摩亚"},
  {"value":"+43","label":"奧地利"},
  {"value":"+61","label":"澳大利亚"}
]

// 全局图形验证码方法并在main.js挂在到原型
export default{
  TencentCaptcha(fun) {
    var Captcha = new TencentCaptcha('2009916787', async (res) => {
      if (res.ret == 0) {
        const res2 = await verifyTencentImageCode({
          randstr: res.randstr,
          ticket: res.ticket,
        })
        if (res2.data.unlockLogin) {
          // this.getPhoneEmailCode(res2.data.unlockLogin)
          fun(res2.data.unlockLogin)
        }
      }
    })
    Captcha.show()
  }
}

// 处理金额逗号
export const priceDispose = (num) => {
  let str = num.toString().replace(/,/g,"");
  let integer = str.split(".")[0];  //获取整数位
  let decimal = str.split(".")[1];  //获取小数
  let res = [];
  if(integer.length > 3){
    let counter = 0;
    for (let i = integer.length - 1; i >= 0; i--) {
        counter++;
        res.unshift(integer[i]);
        if (!(counter % 3) && i != 0) { 
          res.unshift(','); 
        }
    }
    if(decimal){
      res = res.join("") + '.' + decimal;
    }else{
      res = res.join("");
    }
  }else{
    res = str;
  }
  return res;
}
 // 手机和邮箱显示****处理
export const verifyPhoneEmail = (value) => {
  if(value.length == 5) {
    return value.replace(/(\d{1})\d{4}(\d{0})/, '$1****$2');
  }
  if(value.length == 6) {
    return value.replace(/(\d{1})\d{4}(\d{1})/, '$1****$2');
  }
  if(value.length == 7) {
    return value.replace(/(\d{1})\d{4}(\d{2})/, '$1****$2');
  }
  if(value.length == 8) {
    return value.replace(/(\d{2})\d{4}(\d{2})/, '$1****$2');
  }
  if(value.length == 9) {
    return value.replace(/(\d{2})\d{4}(\d{3})/, '$1****$2');
  }
  if(value.length == 10) {
    return value.replace(/(\d{3})\d{4}(\d{3})/, '$1****$2');
  }
  if(value.length > 10) {
    let arr = value.split('')
    arr.push('a')
    let behind = arr.slice(-5, -1)
    let front = arr.slice(0, -5)
    let newFront = front.slice(0, -4)
    return newFront.join('') + '****' + behind.join('');
  }
}

// 处理科学计数法
export function scientificToNumber(num) {
  var m = num.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/);
  return num.toFixed(Math.max(0, (m[1] || '').length - m[2]));
}

export const API_URL = 'https://samoa.hk-wallet.com'
// export const API_URL = 'http://192.168.3.215:8010'

