export default {
  message: {
    agreement: '请阅读并勾选用户协议和隐私条款',
    emailInput: '请输入邮箱',
    phoneInput: '请输入手机号',
    correctEmailInput: '请输入正确的邮箱',
    correctPhoneInput: '请输入正确的手机号',
    passwordInput: '请输入密码',
    correctPassword: '密码长度限制为8~20',
    phoneCodeOK: '发送验证码成功',
    phoneCodeRetry: '发送验证码过于频繁，请稍后重试！',
    loginSuccess: '登录成功',
    registerSuccess: '注册成功',
    TencentMessage: '请通过验证！',
    errorCode: '手机号或验证码错误',
    sysCodeInput: '请输入验证码',
    sysCodeSuccess: '请输入正确的验证码',
    phoneOK: '绑定手机成功',
    emailOK: '绑定邮箱成功',
    logoutText: '此操作将退出登录, 是否继续?',
    hint: '提示',
    out: '退出',
    cancel: '取消',
    cancelOK: '退出成功',
    cancelNo: '已取消退出',
    phoneCode: '请输入手机验证码',
    googleCode: '请输入谷歌验证码',
    emailCode: '请输入邮箱验证码',
    fishingCode: '请输入防钓鱼码',
    fishingRule: '防钓鱼码不能包含特殊字符',
    fishingLength: '防钓鱼码长度为4-20位',
    fishingGoogle: '设置防钓鱼码需要启用2FA验证完成Google验证',
    unbind: '需要綁定了手機和郵箱驗證才能進行解綁操作',
    unbindOK: '解绑成功',
    forgetPassword: '修改密码后您需要重新登录'
  },
  // dual为account-security模块的所有文字
  dual: {
    accountSafety: '账户安全',
    old: '解绑旧验证器',
    dual: '双重验证2FA',
    identity: '身份验证',
    phishingCode: '防钓鱼码',
    setPhishingCode: '设置防钓鱼码',
    withdrawDeposit: '提币白名单',
    google: '谷歌验证器（推荐）',
    protect: '使用安全设备保护您的账户（例如：Yubikey）',
    phoneVerify: '手机验证',
    startUsing: '启用',
    protectDeal: '保护您的交易和账户安全',
    emailVerify: '邮箱验证',
    binding: '绑定',
    unbind: '解绑',
    change: '更改',
    safety: '账户安全',
    loginPassword: '登录密码',
    managePassword: '用于管理您的账户登录密码',
    openWhiteList: '开启白名单后，您的账户仅能提现到白名单内',
    setPhishingCodeHint: '通过设置防钓鱼码，您能辨别收到的邮件是否来自我们',
    close: '关闭',
    site: '地址管理',
    open: '开启',
    set: '设置',
    advanced: '高级安全',
    equipment: '设备管理',
    authorization: '管理已被授权允许登录的账户',
    manage: '管理',
    activity: '账户活动',
    latelyLogin: '最近登录：',
    forbidden: '禁用账户',
    more: '更多',
    phone: '手机',
    email: '邮箱',
    newPhoneCode: '新手机验证码',
    newEmail: '新邮箱验证',
    unbindPhone: '更改手机验证',
    unbindEmail: '更改邮箱验证',
    unbindHint: '解绑注意',
    google2: '谷歌验证器',
    safetyVerify: '安全验证',
    unbindGoogle: '更改绑定 Google 验证',
    sure: '您确定要',
    sureUnbind: '您确定要解绑',
    what: '吗？',
    NO: '取消',
    OK: '确认',
    submit: '提交',
    accomplish: '完成',
    nextStep: '下一步',
    amend: '修改完成',
    unbindPrompt: '为了保护您的资产安全，更改或解绑验证后该账户将禁用支付服务、提币和C2C卖币24 小时。',
    unbindOpen: '提币等相关操作需要开启两项安全验证，仅保留一项将影响提币！',
  },
  forgetPassword: {
    forgetPassword: '忘记密码',
    amendPassword: '修改密码',
    oldPassword: '旧密码',
    newPassword: '新密码',
    okNewPassword: '确认新密码',
    input: '输入发送到',
    code: '的6位验证码',
    inputOldPassword: '请输入旧密码',
    inputNewPassword: '请输入新密码',
    inputNewPasswordOk: '请输入确认新密码',
    text: '为了您的账号安全，更改或解绑验证后该账户将禁用支付服务、提币和C2C卖币24小时。',
    submit: '确认',
    passwordVerify: '密码长度需为8-20位',
    passwordNo: '两次密码输入不一致',
    optionForgetPassword: '选择修改密码',
    phonePassword: '手机密码',
    emailPassword: '邮箱密码',
    forgetPasswordOk: '密码修改成功'
  },
  // kyc 企业验证个人验证
  enterprise: {
    enterpriseOverview: '企业信息总览',
    enterpriseVerify: '企业验证',
    intend: '准备',
    basics: '基础信息',
    upload: '上传文件',
    organization: '机构负责人资料',
    basicsFlow: '即将进入企业基础验证流程。',
    optionEnterpriseType: '请选择注册国家/地区和企业类型。',
    registerNation: '注册国家/地区',
    enterpriseType: '企业类型',
    CertificationImmediately: '立即认证',
    registerCertificate: '注册证书',
    constitution: '公司章程',
    shareholder: '（25％或以上的股东）',
    directorRegister: '董事名册/董事职权证明书',
    recently: '（最近12个月内出具）',
    shareholderAndDirectorRegister: '股东名册/董事职权证明书',
    structureChart: '所有权结构图',
    owner: '请列出所有最终实益拥有人（25%以上），并由董事签署并附上最新日期',
    sanction: '制裁问卷',
    Certificate: '经营状况证明',
    methodOfTriads: '以下三选一',
    governmentWeb: '1.政府网站链接中的公司信息截图，',
    computerTime: '包含电脑桌面时间日期信息',
    DirectorOfAuthority: '2.董事职权证明书',
    goodStanding3: '3.良好的存续证明',
    middleShareholder: '中间层股东信息（如有直接/间接持有25%及以上的公司）',
    shareholderRegister: '1.股东名册',
    enterpriseOverview2: '2.良好的存续证明',
    replenish: '补充材料',
    optional: '（选填）',
    officialWebsite: '公司官网',
    savaDraft: '保存草稿',
    submit: '提交',
    cnAndEnUpload: '目前，我们支持中文，英文，如果上传文件文件为其他语言，请提供原始文件以及官方英语翻译版本',
    enterprisePrepare: '请在开始企业认证前准备以下文件',
    prepare1: '1. 法定全名',
    prepare2: '2. 主要营运地点',
    prepare3: '3. 机构注册的国家',
    prepare4: '4. 纳税人号码',
    prepare5: '5.所有的执行官和董事名单 ("D&Os")',
    prepare6: '6.直接或间接拥有或控制该机构25％或以上股权的所有个人和实体机构名单（“25％或以上的股东”）',
    prepare7: '7. 生意范畴',
    prepare8: '8. 公司实体资产来源说明',
    prepare9: '9. 银行对账单（可选）',
    prepare10: '10.银行名字（可选）',
    prepare11: '11. 实体机构的注册文件：',
    prepare12: '12.董事在职证明书（可选）',
    prepare13: '13. 营业执照',
    prepare14: '14. 组织章程大纲及章程细则',
    prepare15: '15. 组织结构图',
    prepare16: '16.所有的执行官，董事及25％或以上的股东:护照或其他政府发出的身份证明（正面及反面）、地址证明',
    director: '董事',
    del: '删除',
    identityMessage: '身份信息',
    name: '名字',
    nameNo: '名字不能为空',
    nameInput: '请输入名字',
    middleName: '中间名字（若有）',
    middleNameInput: '请输入中间名字',
    IDnumber: '证件号',
    IDnumberNo: '证件号不能为空',
    IDnumberInput: '请输入证件号',
    dateOfBirth: '出生日期',
    dateOfBirthNo: '出生日期不能为空',
    dateOfBirthOption: '请选择出生日期',
    nation: '国家/地区',
    nationNo: '国家/地区不能为空',
    nationOption: '请选择国家/地区',
    certificateNation: '证件签发国/地区',
    certificateNationNo: '证件签发国/地区不能为空',
    certificateNationOption: '请选择证件签发国/地区',
    certificateTypeOption: '请选择要提交的证件类型',
    certificateNo: '证件类型不能为空',
    identificationPhotoFrontNo: '证件照(正面)不能为空',
    identificationPhoto: '证件照',
    front: '(正面)',
    identificationPhotoBackFaceNo: '证件照(背面)不能为空',
    backFace: '(背面)',
    userPhotographNo: '个人照片不能为空',
    userPhotograph: '个人照片',
    directorAdd: '新增董事',
    lastStep: '上一步',
    directorDel: '此操作将删除该董事, 是否继续?, 提示',
    confirm: '确定',
    cancel: '取消',
    DelOk: '删除成功',
    cancelDel: '取消删除',
    companyName: '公司名称',
    companyNameInput: '请输入公司名称',
    registrationNumber: '注册号',
    registrationNumberInput: '请输入注册号',
    establishTime: '成立日期',
    phone: '联系电话',
    capital: '资金来源',
    capitalInput: '请输入资金来源',
    business: '业务性质',
    businessInput: '请输入业务性质',
    applyFor: '申请原因',
    applyForInput: '请输入申请原因',
    registerSite: '注册地址',
    registerNation: '注册国家/地区',
    city: '城市',
    cityInput: '请输入城市',
    street: '街道地址',
    streetInput: '请输入街道地址',
    postalCode: '邮政编码',
    postalCodeInput: '请输入邮政编码',
    save: '保存',
    nextStep: '下一步',
    streetNo: '街道地址不能为空',
    applyForNo: '申请原因不能为空',
    businessNo: '业务性质不能为空',
    capitalNo: '资金来源不能为空',
    cityNo: '城市不能为空',
    companyNameNo: '公司名称不能为空',
    establishTimeNo: '成立日期不能为空',
    phoneNo: '手机号码不能为空',
    phoneLength: '长度在 4 到 20 个数字',
    postalCodeNo: '邮政编码不能为空',
    registrationNumberNo: '注册号不能为空',
    anewUpload: '重新上传',
    uploadNo: '不能为空，请上传',
    uploadOk: '已上传',
    passport: '护照',
    identityCard: '身份证',
    userMessageInput: '填写个人信息',
    identityAuthentication: '身份认证',
    userMessage: '个人信息',
    nationality: '国籍',
    nationalityNo: '国籍不能为空',
    nationalityOption: '请选择国籍',
    familyName: '姓氏',
    familyNameInput: '请输入姓氏',
    familyNameInputNo: '姓氏不能为空',
    legalSite: '合法居住地址',
    legalSiteInput: '请输入合法居住地址',
    legalSiteInputNo: '合法居住地址不能为空',
    governmentSign: '使用政府签发的有效文件',
    governmentIdentityCard: '政府发行的身份证',
    governmentPassport: '政府发行的护照',
    identificationPhotoHand: '手持证件拍照',
    identificationPhotoHandNo: '手持证件拍照不能为空',
    certificateNationSign: '证件签发国',
    reasonNo: '退回原因'
  },
  fishing: {
    setFishing: '设置防钓鱼码',
    whyFishing: '什么是防钓鱼码？',
    fishingAnswer: '防钓鱼码就是您设置的一串字符，能够帮助您识别WeFiv 的网站和邮件',
    appearFishing: '防钓鱼码会在哪里出现？',
    emailFishing: '设置好防钓鱼码后，WeFiv 发给您的每一封邮件都带有防钓鱼码',
    setMyFishing: '设置我的防钓鱼码',
    google: 'Google 验证码',
    googleOk: '验证成功',
    googleInput: '请输入 Google 验证器的6位验证码',
    notarize: '确认',
    fishingCode: '防钓鱼码',
    fishingLength: '请输入4-20个字符，不能包含特殊符号',
    fishingOK: '防钓鱼码已设置',
    setOkFishing: '您已经成功设置防钓鱼码来保护您的账户安全',
    return: '返回',
  },
  equipment: {
    loginEquipment: '登录设备管理',
    hint: '这些设备已被授权允许登录您的账户',
    equipment: '设备',
    time: '时间',
    loginLocation: '登录所在地',
    ip: 'IP地址',
    option: '操作',
    del: '删除',
    details: '详情',
    delTitle: '删除设备信息',
    text: '此操作将永久删除该设备信息, 是否继续?',
    ok: '确定',
    out: '取消',
    delOk: '删除成功!',
    delOut: '已取消删除',
  },
  accountActivity: {
    accountActivity: '近期账号活动',
    loginActivity: '登录活动',
    record: '安全操作记录',
    forbidden: '禁用账户',
    time: '时间',
    source: '来源',
    state: '状态',
    ip: 'IP地址',
    success: '成功',
    error: '失败',
    Activity: '活动',
  },
  phoneEmailBinding: {
    phoneBinding: '绑定手机',
    emailBinding: '绑定邮箱',
    phone: '手机号',
    email: '邮箱',
    google: '绑定Google 验证',
    phoneInput: '请输入手机号',
    emailInput: '请输入邮箱号',
    sysCode: '验证码',
    sysCodeInput: '请输入6位验证码',
    gainSysCode: '获取验证码',
    lastStep: '上一步',
    nextStep: '下一步',
    return: '返回',
    phoneOk2: '您已经成功绑定手机号码来保护您的账户安全',
    emailOk2: '您已经成功绑定邮箱来保护您的账户安全',
    phoneOk: '手机已绑定',
    emailOk: '邮箱已绑定',
    downloadAPP: '下载APP',
    scan: '扫描二维码',
    backups: '备份秘钥',
    succeed: '成功',
    downloadGoogle: '下载并安装Google 身份验证器App',
    install: '我已安装，下一步',
    googleScan: '用Google 验证App 扫描这个二维码',
    APPInput: '如果您无法扫描这个二维码，请在App中手动输入这串字符',
    backupsLocation: '将密钥备份后保存在安全位置',
    message:'若您丢失了手机，此密钥可用来恢复您的身份验证器。否则，客服帮您重置谷歌验证器至少需要 7 天。',
    emailCode: '邮箱验证码',
    phoneCode: '手机验证码',
    googleCode: 'Google 验证码',
    inputGoogle: '请输入 Google 验证器的6位验证码',
    bindingOK: '身份验证器已绑定',
    successBinding: '您已经成功绑定Google 验证器来保护您的账户安全',
    sixCode: '的6位验证码',
  },
  greetRegister: {
    greet: '欢迎来到',
    open: '一起开启数字之旅',
    begin: '开始使用',
  },
  multipleVerification: {
    safety: '安全验证',
    phone: '手机验证',
    email: '邮箱验证',
    google: '谷歌验证',
    nextTime: '下次验证',
  },
  phoneSysCode: {
    IDverification: '账号验证',
    safetyVerification: '安全验证',
    send: '系统将发送验证码到您的',
    phone: '手机',
    check: '，验证码有效期为15分钟，请及时查看',
    note: '短信',
    phoneCodeInput: '并填写验证码',
    Google: '谷歌',
    unbind: '解绑',
    phoneCode: '验证码',
    noteCode: '短信验证码',
    GoogleCode: '谷歌验证码',
    nextStep: '为了您的账号安全，请完成一下验证操作',
    hint: '为了您的账号安全，更改或解绑验证后该账户将禁用支付服务、提币和C2C卖币24小时。',
    resend: '重新发送',
    resend2: '后重新发送',
    noNote: '收不到短信？试试语音验证码',
    resendPhone: '切换至手机验证',
    noSafetyVerification: '安全验证不可用？',
    submit: '提交',
  },
  head: {
    login: '登录',
    money: '大洋洲币',
    transaction: '现货交易',
    condition: '行情',
    buyMoney: '一键买币',
    register: '注册',
    my: '个人账号',
    inform: '通知',
    okAuthentication: '已认证',
    noAuthentication: '未认证',
    underReview: '审核中',
    myCentre: '个人中心',
    accountSecurity: '账户安全',
    logout: '登出',
  },
  verifyOk: {
    text: '确认无误，提交资料',
    text2: '平台运营人员会审核您的信息',
    text3: '一般需要3-5工作日完成。',
    ok: '确认'
  },
  my: {
    okAuthentication: '已认证',
    noAuthentication: '未认证',
    hint: '*您的账号出现异常，暂时无法使用交易功能。如有疑问，请联系客服：acc@wefiv.io',
    activity: '账户活动',
    recommend: '安全推荐',
    equipment: '设备',
    forbidden: '禁用账户',
    more: '查看更多',
    FA2: '启用 2FA',
    authentication: '身份认证',
    fishingCode: '防钓鱼码',
    whiteList: '开启提币白名单',
    open: '已开启',
    standard: '标准身份认证',
    underReview: '审核中',
    set: '设置',
    setOK: '已设置',
    unfold: '打开',
    time: '上次登录时间：',
    verify: '验证',
    esc: '返回个人中心',
  },
  footer: {
    aboutUs: '关于我们',
    contact: '商务联络',
    serviceAgreement: '服务协议',
    Advertising: '隐私说明',
    announcement: '公告中心',
    message: '资讯',
    help: '帮助',
    helpCentre: '帮助中心',
    customerService: '在线客服',
    rateStandards: '费率标准',
    transactionRules: '交易规则',
    suggest: '建议和反馈',
    capital: '资金',
    topUp: '法币充值',
    withdrawDeposit: '法币提现',
    encryptionTopUp: '加密货币充币',
    encryptionWithdrawDeposit: '加密货币提现',
    learn: '学习',
    oceaniaCurrency: '买大洋洲币',
    buyBTC: '买BTC',
    buyUSDT: '买USDT',
    buyETH: '买ETH',
    buySOL: '买SOL',
  },
  home: {
    bank: '萨摩亚银行',
    recommend: '买币就上Nuru Bubble 交易所',
    introduce: '由国家背书的正规交易所',
    guide: '新手指引',
    guideBegin: '即刻开启数字货币交易学习',
    understand: '3分钟急速了解法币交易',
    howBuy: '如何购买数字货币',
    sell: '如何出售数字货币',
    trend: '市场趋势',
    presentTrend: '当前市场趋势',
    name: '名称',
    newest: '最新价',
    moneyVariation: '24h涨跌',
    viewMore: '查看更多',
    beginDeal: '开始交易',
    beginDealTravel: '开启你的数字货币之旅',
    serviceCharge: '0手续费卖数字货币',
    introduce1: '使用多种支付方式交易数字货币，0手续费，安全快捷',
    rate: '最优的交易费率',
    introduce2: '优惠的交易费率，富有竞争力的VIP权益，享受最优品质的服务',
    guarantee: '可信赖的安全保障',
    introduce3: '我们复杂的安全措施和SAFU基金保护您的数字资产免受所有风险',
    customerService: '24*7小时客服支持',
    introduce4: '24*7小时全天候、全日制运营模式，第一时间为您解答相关咨询',
    register: '立即注册',
    buy: '购买',
  },
  buyMoney: {
    buyMoney: '一键买币',
    buy: '购买',
    sell: '出售',
    pay: '我要支付',
    receive: '我将收到≈',
    serviceCharge: '0手续费购买',
    buyProcess: '购买流程',
    buyMoneyProcess: 'Nuru Bubble 一键购买流程',
    openAnAccount: '1.开户',
    bankOpenAnAccount: 'Nuru Bubble 银行开户',
    deposit: '2.存款',
    phoneDeposit: '通过电汇存款进银行账户',
    buyCoin: '3.买币',
    libertyBuy: '自由买卖交易货币',
    buyAdvantage: '买币优势',
    ExchangeBuy: '用 Nuru Bubble Exchange 买币的优势',
    buyAdvantage1: '安全比特币等数字货币',
    buyAdvantage2: '低交易手续费',
    buyAdvantage3: '更优惠的价格',
  },
  condition: {
    condition: '行情',
    optional: '自选',
    AllCurrencies: '所有币种',
    search: '搜索',
    name: '名称',
    newest: '最新价',
    moneyVariation: '24h涨跌',
    numberOfTransaction: '24h交易量',
    marketValue: '市值',
    details: '详情',
    deal: '交易',
    collectOK: '收藏成功',
    cancelCollect: '取消收藏成功',
  },
  money: {
    oceaniaCurrency: '大洋洲币',
    title: 'Nuru Bubble 官网稳定币，XXXXXXX',
    oceaniaCurrencyWhy: '什么是大洋洲币',
    scene: '应用场景',
    cooperation: '合作伙伴',
    buyOceaniaCurrency: '购买大洋洲币',
  },
  login: {
    title: '登录',
    emailLogin: '邮箱登录',
    phoneLogin: '手机登录',
    loginWay: '选择登录方式',
    phone: '手机号',
    email: '邮箱',
    phoneInput: '请输入手机号',
    emailInput: '请输入邮箱',
    loginPassword: '登录密码',
    passwordInput: '请输入密码',
    forgetPassword: '忘记密码',
    register: '注册',
  },
  register: {
    registerOneself: '注册个人账号',
    emailRegister: '邮箱注册',
    phoneRegister: '手机注册',
    registerEnterprise: '注册企业账号',
    international: '居住国家/地区*请按您的实际国际选择',
    phone: '手机号',
    email: '邮箱',
    phoneInput: '请输入手机号',
    emailInput: '请输入邮箱',
    passwordInput: '请输入密码',
    installPassword: '设置密码',
    read: '我已阅读并同意',
    userAgreement: '《用户协议》',
    PrivacyPolicy: '《隐私条款》',
    register: '注册',
    alreadyHave: '已有账号',
    login: ' 登录',
    optionInternational: '请选择居住国家/地区',
    and: '和',
  },
  inform: {
    all: '全部通知',
    deal: '交易通知',
    activity: '活动通知',
    system: '系统通知',
    empty: '清空',
    read: '全部为已读',
    title: '通知',
  },
  phoneCode: [
    { value: '+852', label: '中国香港' },
    { value: '+86', label: '中国' },
    { value: '+853', label: '中国澳门' },
    { value: '+886', label: '中国台湾' },
    { value: '+33', label: '法国' },
    { value: '+82', label: '韩国' },
    { value: '+1', label: '美国' },
    { value: '+44', label: '英国' },
    { value: '+65', label: '新加坡' },
    { value: '+34', label: '西班牙' },
    { value: '+54', label: '阿根廷' },
    { value: '+49', label: '德国' },
    { value: '+81', label: '日本' },
    { value: '+66', label: '泰国' },
    { value: '+84', label: '越南' },
    { value: '+95', label: '缅甸' },
    { value: '+39', label: '意大利' },
    { value: '+351', label: '葡萄牙' },
    { value: '+91', label: '印度' },
    { value: '+1684', label: '美属萨摩亚' },
    { value: '+43', label: '奧地利' },
    { value: '+61', label: '澳大利亚' },
  ],
  language: {
    language: 'English',
    zh: 'zh-CN',
    en: 'en',
    tw: 'tw',
    zhName: 'Chinese(CN)',
    enName: 'English',
    twName: 'Chinese(TC)',
  },
}
