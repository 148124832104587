import axios from 'axios'
import { Loading, Message } from 'element-ui'
import store from '@/store/index'
import router from '@/router/index'
import Vue from 'vue'

// 定义 loading
let loading
let url = ''
// if(window.location.hostname =="localhost"){
// var url='https://test.api.hk-wallet.com'
// }else{
// var url=''
// }

// loading开始 方法
function startLoading() {
  Vue.prototype.$setLoading(true)
  // element-ui loading 服务调用方式
  // loading = Loading.service({
  //   lock: true,
  //   text:
  //     store.state.language === 'zh-CN'
  //       ? '加载中'
  //       : store.state.language === 'tw'
  //       ? '加載中'
  //       : 'loading',
  //   spinner: 'el-icon-loading', // 自定义图标
  //   background: 'rgba(0, 0, 0, 0)',
  // })
}

// loading结束 方法
function endLoading() {
  // loading.close()
  Vue.prototype.$setLoading(false)
}

let requestingCount = 0;

const handleRequestLoading = () => {
  if (requestingCount === 0) {
    startLoading();
  }
  requestingCount += 1;
}
const handleResponseLoading = () => {
  if (requestingCount <= 0) return;
  requestingCount -= 1;
  if (requestingCount === 0) {
    endLoading();
  }
}
const service = axios.create({
  baseURL: url, // url = base url + request url
  timeout: 60000, // request timeout
})

// request interceptor
const whiteUrl = [
  '/verifyTencentImageCode',
  '/sms/getCode',
  '/email/getCode',
  '/login',
  '/registerUser',
  '/getBinance24hr',
  '/getNoticeList',
]

service.interceptors.request.use(
  (config) => {
    config.headers['lang'] =
    store.state.language === 'en'
        ? 'en-US'
        : store.state.language === 'tw'
        ? 'zh-HK'
        : 'zh-CN'
    // 请求加上token， whiteUrl数组为不需要token的接口
    if (store.state.userInfo.token && !whiteUrl.includes(config.url) && !config.chartsShow) {
      config.headers['Authorization'] = store.state.userInfo.token
    }
    if(!(config.loading == false)) {
      handleRequestLoading()
    }
    return config
  },
  (error) => {
    console.log(error) // for debug
    handleResponseLoading()
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response) => {
    handleResponseLoading()
    const res = response.data
    if (response.status !== 200 || (res.code !== '0000' && res.code !== undefined)) {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000,
      })
      if (res.code === '1200') {
        store.commit('remTokenInfo')
        store.commit('setAll')
        router.push('/login')
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      if(response.config.updateUserInfo) {
        store.dispatch('getCurrentUser')
      }
      return res
    }
  },
  (error) => {
    handleResponseLoading()
    if( error.config.url.split('?')[0] === '/api/v3/ticker/24hr') {
      // 因为币安的接口不能模糊查询，否则会报400 所以专门处理
      if(error.request.status == 400) {
        // console.log(error.message)
        Message({
          message: error.message,
          type: 'error',
          duration: 5 * 1000,
        })
      }
      // return Promise.reject(error)
    } else {
      Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000,
      })
    }
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

export default service
