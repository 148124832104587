import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/common/index.css'
import 'element-ui/lib/theme-chalk/index.css'
import element from './elementUi/index'
import md5 from 'js-md5';
import i18n from '@/lang/index'
import './router/permission'
import limit from '@/common/validation.js'
import formatDate from '@/common/formatDate.js'
//引入 echarts
import * as echarts from 'echarts'
import formatTime from './common/fileters.js'
import Loading from '@/components/loading/index'
Vue.use(element)
Vue.use(Loading);
// Vue.use(element, {
//   i18n: (key, value) => i18n.t(key, value)
// })

Vue.prototype.$formatDate = formatDate
Vue.prototype.$formatTime = formatTime
Vue.prototype.$md5 = md5;
Vue.prototype.$echarts = echarts;
Vue.prototype.$limit = limit;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  created(){
    // 实例创建完成后被立即调用rem换算方法,解决内容闪现问题
		setRemPc();
        window.addEventListener('resize', setRemPc);//浏览器窗口大小改变时调用rem换算方法
	},
}).$mount('#app')


// 挂载loading组件附带的方法
Vue.prototype.$setLoading = function (props) {
  if (typeof props === 'boolean') props = { spinning: props }
  if (Object.prototype.toString.call(props) !== '[object Object]') props = {}
  // console.log(this.$app);
  // console.log(props);
  
//  this => Vue.Prototype   谁调用this指向谁
  this.$app.loadingProps = {
    ...props
  }
}

Vue.directive('inp-num', {
  inserted(el, vDir, vNode) {
    // vDir.value 有指令的参数
    let content
    // 设置输入框的值,触发input事件,改变v-model绑定的值
    const setVal = (val) => {
      if (vNode.componentInstance) {
        // 如果是自定义组件就触发自定义组件的input事件
        vNode.componentInstance.$emit('input', val)
      } else {
        // 如果是原生组件就触发原生组件的input事件
        el.value = val
        el.dispatchEvent(new Event('input'))
      }
    }
    // 按键按下=>只允许输入 数字/小数点/减号
    el.addEventListener('keypress', (event) => {
      let arg_toFixed = 0 // 默认保留至整数
      if (vDir.value.toFixed) {
        arg_toFixed = parseFloat(vDir.value.toFixed)
      }
      const e = event || window.event
      const inputKey = String.fromCharCode(
        typeof e.charCode === 'number' ? e.charCode : e.keyCode,
      )
      const re = /\d|\.|-/
      content = e.target.value
      // 定义方法,阻止输入
      function preventInput() {
        if (e.preventDefault) {
          e.preventDefault()
        } else {
          e.returnValue = false
          // return false
        }
      }
      if (!re.test(inputKey) && !e.ctrlKey) {
        preventInput()
      } else if (content.indexOf('.') > 0 && inputKey === '.') {
        // 已有小数点,再次输入小数点
        preventInput()
      } else if (
        // 小数点后的位数超过不可输入
        content.indexOf('.') > 0 &&
        content.length - content.indexOf('.') > arg_toFixed
      ) {
        preventInput()
      }
    })
    // 规范
    function standard(event) {
      const e = event || window.event
      content = parseFloat(e.target.value)
      if (!content) {
        // content = 0.00
        if (content == 0) {
          content = 0
        } else {
          content = ''
        }
      }
      e.target.value = content ? content : vDir.value.min
      let arg_max = ''
      let arg_min = ''
      if (vDir.value) {
        arg_max = parseFloat(vDir.value.max) + '' // 这里特意转化成字符串形式 防止当最大或者最小只为0时，判断为false
        arg_min = parseFloat(vDir.value.min) + '' // 这里特意转化成字符串形式 防止当最大或者最小只为0时，判断为false
      }
      if (arg_max && +content > arg_max) {
        e.target.value = arg_max
        content = arg_max
      }
      if (arg_min && +content < arg_min) {
        e.target.value = arg_min
        content = arg_min
      }

      setVal(e.target.value)
    }
    // 输入中文的时候
    el.addEventListener('compositionend', (event) => {
      standard(event)
    })
    // 失去焦点=>保留指定位小数
    el.addEventListener('focusout', (event) => {
      // 此处会在 el-input 的 @change 后执行
      standard(event)
    })
  },
})


//rem计算
function setRemPc() {
  var width = 100/1510;// 表示1510的设计图,使用100PX的默认值
  var bodyWidth = document.body.clientWidth;// 当前窗口的宽度
  if(bodyWidth > 1280 && bodyWidth <= 1920) { // rem适配 屏幕缩放太大或太小会错乱 加个判断在多少尺寸之内才会变化
    var rem = bodyWidth * width;// 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
  document.getElementsByTagName('html')[0].style.fontSize = rem + 'px';
  } else {
    document.getElementsByTagName('html')[0].style.fontSize = 100 + 'px'; // 如果不在判断尺寸之内就设置为基础值, 这个基础值100对应第一个100
  }
}
//window.addEventListener('load', setRemPc);
