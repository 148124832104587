import Vue from 'vue'
import Vuex from 'vuex'
import { setToken, getToken, remToken } from '../store/token'
import { getCurrentUser } from '@/api/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: getToken() || {},
    codeShow: false, // 控制验证码框显示隐藏
    greetDialogVisible: false, // 控制歡迎來到Nuru Bubble彈框顯示隱藏
    safetyDialogVisible: false, // 安全驗證彈框提示隱藏
    phoneEmailDialogVisible: false, // 綁定解除/更改手機號郵箱google及安全验证手机邮箱谷歌彈框顯示隱藏
    fishingShow: false, // 防钓鱼码弹框显示与隐藏
    unbindShow: false, // 安全验证邮箱手机号谷歌弹框显示与隐藏
    updateShow: false, // 更改手机邮箱谷歌弹框显示与隐藏
    // forgetPassword: false, // 修改手机号/邮箱密码弹框显示与隐藏
    enterpriseOrder: 0, // kyc企业认证控制组件显示
    dialog2FAVisible: false, // 账户安全解绑更改谷歌、邮箱、手机
    language: localStorage.getItem('changeLang') ? localStorage.getItem('changeLang') : 'zh-CN',
    agreementInfo: {
      title: '',
      isShowDialog: false,
    }
  },
  getters: {},
  mutations: {
    setAgreementInfo(state, newData) {
      state.agreementInfo = newData
    },
    setTokenInfo(state, newSetToken) {
      state.userInfo = newSetToken
      localStorage.removeItem('changeLang')
      setToken(newSetToken)
    },
    remTokenInfo(state) {
      state.userInfo = {}
      remToken()
    },
    // 关闭所有弹框
    setAll(state) {
      state.codeShow = false
      state.greetDialogVisible = false
      state.safetyDialogVisible = false
      state.phoneEmailDialogVisible = false
      state.fishingShow = false
      state.unbindShow = false
      state.updateShow = false
      state.dialog2FAVisible = false
    },
    // 控制验证码框显示隐藏
    setCodeShow(state, newCodeShow) {
      state.codeShow = newCodeShow
    },
    // 控制歡迎來到Nuru Bubble彈框顯示隱藏
    setGreetDialogVisible(state, newCodeShow) {
      state.greetDialogVisible = newCodeShow
    },
    // 安全驗證彈框提示隱藏
    setSafetyDialogVisible(state, newCodeShow) {
      state.safetyDialogVisible = newCodeShow
    },
    // 綁定手機號郵箱google彈框顯示隱藏
    setPhoneEmailDialogVisible(state, newCodeShow) {
      state.phoneEmailDialogVisible = newCodeShow
    },
    // 防钓鱼码弹框显示与隐藏
    setFishingCodeDialogVisible(state, newCodeShow) {
      state.fishingShow = newCodeShow
    },
    // 安全验证邮箱手机号谷歌弹框显示与隐藏
    setUnbindShowDialogVisible(state, newCodeShow) {
      state.unbindShow = newCodeShow
    },
    // 更改手机邮箱谷歌弹框显示与隐藏
    setUpdateShowDialogVisible(state, newCodeShow) {
      state.updateShow = newCodeShow
    },
    // // 修改手机号/邮箱密码弹框显示与隐藏
    // setForgetPasswordDialogVisible(state, newCodeShow) {
    //   state.forgetPassword = newCodeShow
    // },
    // 更改kyc企业认证控制组件显示
    setEnterpriseOrder(state, newCodeShow) {
      state.enterpriseOrder = newCodeShow
    },
    // 注意解绑和更改账户安全解绑更改谷歌、邮箱、手机
    setDialog2FAVisible(state, newCodeShow) {
      state.dialog2FAVisible = newCodeShow
    },
    // 同步操作直接修改state里面的語言数据
    setLanguage: (state, data) => {
      state.language = data;
      localStorage.setItem('changeLang', data);
  },
  },
  actions: {
    // 获取用户最新信息并调用mutations里setTokenInfo方法把最新用户信息存进本地
    async getCurrentUser(context, payload) {
      const res = await getCurrentUser()
      const token = context.state.userInfo.token
      const userInfo = res.data
      userInfo.token = token
      context.commit('setTokenInfo', userInfo)
    }
  },
  modules: {},
})
