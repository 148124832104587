<template>
  <div class="loading-content">
    <div class="loading">
      <div class="three1"></div>
      <div class="three2"></div>
      <div class="three3"></div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.loading-content {
  // background: rgba(0, 0, 0, 0);
  // width: 100%;
  // height: 100%;
  position: fixed;
  z-index: 9999999999999;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  .loading {
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .loading > div {
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-block;
    background-color: #5baddb;
    -webkit-animation: three 1.4s infinite ease-in-out;
    animation: three 1.4s infinite ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  .loading .three1 {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
  }

  .loading .three2 {
    -webkit-animation-delay: -0.15s;
    animation-delay: -0.15s;
  }

  @-webkit-keyframes three {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes three {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }
}
</style>
