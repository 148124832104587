<template>
<div class="footer_content">
  <div class="footer">
    <div class="middle-box">
      <div class="img-box">
        <img src="@/assets/Combined Shape@2x.png" alt="">
      </div>
      <ul>
      <li>服务</li>
      <li><a target="_blank" href="https://forms.gle/Bwoq25nnqU8MAaSr8">合作申请</a></li>
      <!-- <li>支持币种</li>
      <li>费率标准</li> -->
    </ul>
    <ul>
      <li>产品</li>
      <!-- <li>Hunter Wallet</li> -->
      <li @click="$router.push('/cryptoAtm')">ATM</li>
      <li @click="$router.push('/otc')">场外交易</li>
      <li @click="$router.push('/deal')">币币交易</li>
    </ul>
    <ul>
      <li>条款相关</li>
      <li @click="setAgreementInfo({ title: $t('privacyPolicy.privacyPolicy'), isShowDialog: true  })">隐私协议</li>
      <li @click="setAgreementInfo({ title: $t('userAgreement.userAgreement'), isShowDialog: true  })">用户协议</li>
      <li><a target="_blank" href="https://docs.google.com/document/d/1qEzsoiF81KYew0RabEuDCNwiWtFId5_xf-n-2nIz_sI/edit?usp=sharing">反洗钱和KYC</a></li>
    </ul>
    </div>
    <!-- <div class="language">
      <div class="text">Community</div>
      <div class="cn">简体中文</div>
    </div> -->
    <div class="footer_text">© Copyright 2023 Nuru Bubble - All Rights Reserved</div>
  </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  data() {
    return {

    }
  },
  methods: {
    ...mapMutations(['setAgreementInfo']),
  }
}
</script>

<style lang="less" scoped>
.footer_content {
  position: relative;
  background: #3c464d;
  padding: 0.655rem 0 0.11rem 0;
  box-sizing: border-box;
  height: 3.33rem;
  .footer {
    width: 9.75rem;
    margin: 0 auto;
  display: flex;
  font-size: 0.12rem;
  color: #fff;
  box-sizing: border-box;
  justify-content: space-between;
  padding-right: 0.9rem;
  .middle-box {
    width: 100%;
    display: flex;
    // align-items: center;
    justify-content: space-around;
    .img-box {
      display: flex;
      align-items: center;
      img {
      width: 1.14rem;
      height: .18rem;
    }
    }
    ul {
    // width: 1.7rem;
  }
  ul li {
    margin-bottom: 0.07rem;
    a {
      font-size: 0.12rem;
  color: #fff;
    }
    a:hover {
    color: #33afe0;
  }
  }
  ul li:nth-child(1) {
    font-size: 0.17rem;
    font-weight: 500;
  }
  ul li:hover {
    color: #33afe0;
  }
  }
}

  .footer_text {
    position: absolute;
    bottom: 0.11rem;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
</style>
