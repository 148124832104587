<template>
  <div class="head">
    <div class="head_content">
      <div class="content_left">
        <img
          @click="$router.push('/')"
          src="@/assets/Combined Shape@2x.png"
          class="img"
          alt=""
        />
        <ul>
          <!-- <li @click="$router.push('/buyCurrency')">
            {{ $t('head.buyMoney') }}
          </li>
          <li @click="$router.push('/condition')">
            {{ $t('head.condition') }}
          </li> -->
          <li @click="$router.push('/cryptoAtm')">
            {{ 'Crypto ATM' }}
          </li>
          <li @click="$router.push('/otc')">
            {{ '场外交易' }}
          </li>
          <li @click="$router.push('/deal')">
          {{ $t('head.transaction') }}
          </li>
          <!-- <li @click="$router.push('/oceaniaCurrency')">
            {{ $t('head.money') }}
          </li> -->
        </ul>
      </div>
      <div class="content_right">
        <router-link v-if="!userInfo.token" class="login" to="/login">{{
          $t('head.login')
        }}</router-link>
        <ul>
          <el-popover
            popper-class="menu-popover"
            placement="bottom"
            width="200"
            trigger="hover"
            v-if="userInfo.token"
          >
            <div class="popover_content">
              <div class="user">
                <div class="user_left">
                  <img class="left_img" src="@/assets/Group@222x.png" alt="" />
                </div>
                <div class="user_right">
                  <p class="email">{{ userInfo.email && userInfo.mobile ? verifyPhoneEmail(userInfo.email) : verifyPhoneEmail((userInfo.email || userInfo.mobile)) }}</p>
                  <p @click="userInfo.certificationStatus == 0 ? $router.push(`/${userInfo.userType == 1 ? 'enterprise' : 'personal'}`) : ''" :style="userInfo.certificationStatus == 1 ? 'background-color: #52D132;' : (userInfo.certificationStatus == 0 ? 'cursor: pointer;' : '')" class="text">{{ userInfo.certificationStatus == 0 ? $t('head.noAuthentication') : (userInfo.certificationStatus == 1 ? $t('head.okAuthentication') : $t('head.underReview')) }}</p>
                </div>
              </div>
              <div class="list">
                <div class="list_user">
                  <div class="list_text list_height" @click="$router.push('/my')"><img src="@/assets/Group 3@2x1.png" alt="" />{{ $t('head.myCentre') }}</div>
                  <div class="list_text list_height" @click="$router.push('/wallet')"><img src="@/assets/qianbao.png" alt="" />{{ '我的钱包' }}</div>
                  <div class="list_text list_height" @click="$router.push('/accountSecurity')"><img src="@/assets/Group@232).png" alt="" />{{ $t('head.accountSecurity') }}</div>
                </div>
              </div>
              <div @click="open" class="out list_text"><img src="@/assets/Group@2xxx.png" alt="" />{{ $t('head.logout') }}</div>
            </div>
            <li slot="reference">{{ $t('head.my') }}</li>
          </el-popover>
          <li v-if="!userInfo.token" @click="$router.push('/register')">
            {{ $t('head.register') }}
          </li>
          <li v-if="userInfo.token" @click="$router.push('/inform')">
            {{ $t('head.inform') }}
          </li>
          <li>
            <el-dropdown
              trigger="click"
              class="international"
              @command="handleSetLanguage"
            >
              <span class="language">{{ $t('language.language') }}</span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :disabled="language === $t('language.zh')"
                  command="zh-CN"
                  ><span>{{ $t('language.zhName') }}</span></el-dropdown-item
                >
                <el-dropdown-item
                  :disabled="language === $t('language.tw')"
                  command="tw"
                  ><span>{{ $t('language.twName') }}</span></el-dropdown-item
                >
                <!-- <el-dropdown-item
                  :disabled="language === $t('language.en')"
                  command="en"
                  ><span>{{ $t('language.enName') }}</span></el-dropdown-item
                > -->
              </el-dropdown-menu>
            </el-dropdown>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { verifyPhoneEmail } from '@/common/validation'
export default {
  name: 'navigationBar',
  data() {
    return {
      verifyPhoneEmail
    }
  },
  computed: {
    ...mapState(['language', 'userInfo']),
  },
  methods: {
    ...mapMutations(['remTokenInfo']),
    // 把选择的语言传入vuex
    handleSetLanguage(lang) {
      this.$i18n.locale = lang
      this.$store.commit('setLanguage', lang)
    },
    open() {
        this.$confirm(this.$t('message.logoutText'), this.$t('message.hint'), {
          confirmButtonText: this.$t('message.out'),
          cancelButtonText: this.$t('message.cancel'),
          type: 'warning'
        }).then(() => {
          this.remTokenInfo()
          this.$router.push('/')
        }).catch(() => {
          this.$message({
            type: 'info',
            message: this.$t('message.cancelNo')
          });
        });
      },
  },
}
</script>
<style lang="less">
.menu-popover {
  width: 2.95rem !important;
  background: #2a2e2f !important;
  border-color: #2a2e2f !important;
  top: 0.69rem !important;
  left: 10.65rem !important;
  font-size: 0.16rem !important;
  box-sizing: border-box !important;
  padding: 0.22rem 0 0.22rem 0.26rem !important;
  height: 4.8rem !important;
}
.menu-popover[x-placement^='bottom'] .popper__arrow {
  border-bottom-color: #2a2e2f !important;
  left: 0.5rem !important;
  top: -0.23rem !important;
}
.menu-popover[x-placement^='bottom'] .popper__arrow::after {
  border-bottom-color: #2a2e2f !important;
}
.menu-popover .popper__arrow {
  border-width: 0.12rem !important;
}
.el-dropdown-menu {
  z-index: 2020 !important;
}
.popover_content {
  .user {
    display: flex;
    align-items: center;
    margin-bottom: 0.41rem;
  }
  .user_left {
    margin-right: 0.14rem;
    height: 100%;
    .left_img {
      width: 0.5rem;
    }
  }
  .user_right {
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .email {
      font-weight: 400;
      margin-bottom: 0.04rem;
    }
    .text {
      font-weight: 500;
      width: 0.53rem;
      height: 0.25rem;
      background: #FF920C;;
      border-radius: 0.13rem;
      font-size: 0.12rem;
      text-align: center;
      line-height: 0.25rem;
    }
  }
  .list_text {
    display: flex;
    align-items: center;
    color: #fff;
    cursor: pointer;
    img {
      width: 0.2rem;
      height: 0.2rem;
      margin-right: 0.09rem;
    }
  }
  .list_height {
    padding-bottom: 0.16rem;
    padding-top: 0.16rem;
    border-bottom: .01rem solid #434444;
    display: flex;
    align-items: center;
  }
  .list_text:hover {
    color: #33AFE0;
  }
  .out {
    position: absolute;
    bottom: 0.22rem;
    left: 0.26rem;
  }
}
</style>
<style lang="less" scoped>
.head {
  z-index: 2010;
  width: 100%;
  color: #fff;
  background: rgba(0, 0, 0, 0.82);
  height: 0.64rem;
  position: fixed;
  min-width: 12.8rem;
  .head_content {
    display: flex;
    padding-right: 1rem;
    padding-left: 1.42rem;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    ul {
      display: flex;
      font-size: 0.13rem;
    }
    ul li:hover {
      color: #33afe0;
    }
    .content_left {
      display: flex;
      align-items: center;
      .img {
        width: 1.14rem;
        height: 0.18rem;
        margin-right: 0.4rem;
        cursor: pointer;
      }
      ul li {
        margin-right: 0.22rem;
      }
      ul li:nth-child(2) {
        margin-right: 0.31rem;
      }
    }
    .content_right {
      display: flex;
      align-items: center;
      .login {
        display: block;
        color: #fff;
        width: 1.28rem;
        height: 0.43rem;
        background: #33afe0;
        border-radius: 0.04rem;
        font-size: 0.15rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      ul {
        font-size: 0.13rem;
      }
      ul li {
        margin-left: 0.43rem;
      }
    }
  }
  .language {
    color: #fff;
    font-size: 0.13rem;
  }
  .language:hover {
    color: #33afe0;
  }
  .international {
    display: flex;
  }
}
</style>
