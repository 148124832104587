import service from "@/api/http";
// 校验腾讯云滑块验证码
export function verifyTencentImageCode(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/verifyTencentImageCode',
    method: 'POST',
    data
  })
}

// 获取短信验证码服务
export function smsGetCode(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/sms/getCode',
    method: 'POST',
    data
  })
}

// 获取邮箱验证码服务
export function emailGetCode(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/email/getCode',
    method: 'POST',
    data
  })
}

// 获取谷歌验证码服务
export function googleGetCode(data, loading) {
  return service({
    updateUserInfo: true,
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/google/getCode',
    method: 'POST',
    data
  })
}

// 注册用户
export function registerUser(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/registerUser',
    method: 'POST',
    data
  })
}

// 邮箱/手机号密码登录
export function emailPhoneLogin(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/login',
    method: 'POST',
    data
  })
}

// 校验邮箱验证码服务
export function emailCheckCode(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/email/checkCode',
    method: 'POST',
    data
  })
}

// 校验短信验证码
export function phoneCheckCode(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/sms/checkCode',
    method: 'POST',
    data
  })
}

// 校验谷歌验证码
export function googleCheckCode(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/google/checkCode',
    method: 'POST',
    data
  })
}

// 绑定邮箱/手机
export function bindingUserData(data, loading) {
  return service({
    updateUserInfo: true,
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/bindingUserData',
    method: 'POST',
    data
  })
}

// 用户添加自选货币行情
export function addSysCollectionCurrency(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/addSysCollectionCurrency',
    method: 'POST',
    data
  })
}

// 查看用户自选货币行情模块列表
export function getSysCollectionCurrencyList(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/getSysCollectionCurrencyList',
    method: 'POST',
    data
  })
}

// 取消用户自选货币行情
export function cancelSysCollectionCurrency(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/cancelSysCollectionCurrency',
    method: 'POST',
    data
  })
}

// 查看公告模块列表
export function getNoticeList(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/getNoticeList',
    method: 'POST',
    data
  })
}

// 查看活跃的广告列表
export function getActiveAdvertisementList(loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/getActiveAdvertisementList',
    method: 'GET',
  })
}

// 个人查看用户登录信息
export function getSysLoginInfoListByUser(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/getSysLoginInfoListByUser',
    method: 'POST',
    data
  })
}

// 有updateUserInfo: true参数的接口会改变个人信息，设置为true将为调获取最新个人信息接口 

// 设置防钓鱼码
export function setPhishingCode(data, loading) {
  return service({
    updateUserInfo: true,
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/setPhishingCode',
    method: 'POST',
    data
  })
}

// 查看当前登录用户信息
export function getCurrentUser(loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/getCurrentUser',
    method: 'GET',
  })
}

// 个人查看用户安全操作记录
export function getSysOperLogListByUser(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/getSysOperLogListByUser',
    method: 'POST',
    data
  })
}

// 个人查看登录设备列表
export function getSysLoginDeviceListByUser(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/getSysLoginDeviceListByUser',
    method: 'POST',
    data
  })
}

// 修改登录日志状态
export function updateSysLoginInfoStatus(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/updateSysLoginInfoStatus',
    method: 'POST',
    data
  })
}

// 解绑谷歌验证码
export function unbindCode(data, loading) {
  return service({
    updateUserInfo: true,
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/google/unbindCode',
    method: 'POST',
    data
  })
}

// 查看保存的企业用户信息
export function getSysUserEnterprise(loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/getSysUserEnterprise',
    method: 'GET',
  })
}

// 创建企业用户
export function createSysUserEnterprise(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/createSysUserEnterprise',
    method: 'POST',
    data
  })
}

// 修改企业用户
export function updateSysUserEnterprise(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/updateSysUserEnterprise',
    method: 'POST',
    data
  })
}

// 保存企业对应文件
export function createSysUserEnterpriseFile(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/createSysUserEnterpriseFile',
    method: 'POST',
    data
  })
}

// 修改企业对应文件
export function updateSysUserEnterpriseFile(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/updateSysUserEnterpriseFile',
    method: 'POST',
    data
  })
}

// 查看保存的企业对应文件
export function getSysUserEnterpriseFile(loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/getSysUserEnterpriseFile',
    method: 'GET',
  })
}

// 查看企业用户保存的董事资料
export function getSysUserEnterpriseDirector(loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/getSysUserEnterpriseDirector',
    method: 'GET',
  })
}

// 创建企业用户对应董事的资料
export function createSysUserEnterpriseDirector(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/createSysUserEnterpriseDirector',
    method: 'POST',
    data
  })
}

// 批量修改企业用户对应董事的资料
export function updateSysUserEnterpriseDirectorList(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/updateSysUserEnterpriseDirectorList',
    method: 'POST',
    data
  })
}

// 创建个人用户
export function createSysUserPersonal(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/createSysUserPersonal',
    method: 'POST',
    data
  })
}

// 修改个人用户
export function updateSysUserPersonal(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/updateSysUserPersonal',
    method: 'POST',
    data
  })
}

// 查看保存的个人用戶信息
export function getSysUserPersonal(loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/getSysUserPersonal',
    method: 'GET',
  })
}

// 重置密码
export function resetPassword(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/resetPassword',
    method: 'POST',
    data
  })
}

// 忘记密码
export function forgetPassword(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/forgetPassword',
    method: 'POST',
    data
  })
}

// 查看币种管理模块列表
export function getCurrencyList(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/getCurrencyList',
    method: 'POST',
    data
  })
}

// 限价市价止盈止损买入卖出
export function CryptocurrencyBuySell(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/CryptocurrencyBuySell',
    method: 'POST',
    data
  })
}

// 查询交易委托记录
export function QueryOrder(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/queryOrder',
    method: 'POST',
    data
  })
}


// 获取可用余额
export function getWalletData(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/getWalletData',
    method: 'POST',
    data
  })
}

// 查询资产管理
export function getAssetList(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/getAssetList',
    method: 'POST',
    data,
  })
}

// 获取钱包地址
export function getWalletAddress(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/getWalletAddress',
    method: 'POST',
    data,
  })
}

// 用户查看数字货币历史记录
export function getSysDigitalWalletList(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/getSysDigitalWalletList',
    method: 'POST',
    data,
  })
}


// 导出钱包历史记录
export function exportSysDigitalWalletLog(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/exportSysDigitalWalletLog',
    method: 'POST',
    responseType: 'blob',
    data,
  })
}

// 查看充币配置
export function getConfigurationRecharge(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/getConfigurationRecharge',
    method: 'POST',
    data,
  })
}

// 查看充币配置列表
export function getConfigurationRechargeList(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/getConfigurationRechargeList',
    method: 'POST',
    data,
  })
}

// 撤销正在交易的订单
export function cancelOrder(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/cancelOrder',
    method: 'POST',
    data,
  })
}

// 查看提币配置列表
export function getConfigurationWithdrawalList(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/getConfigurationWithdrawalList',
    method: 'POST',
    data,
  })
}

// 数字货币提现
export function chainWithdrawal(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/chainWithdrawal',
    method: 'POST',
    data,
  })
}

// 提现页面 现货账户金额 资金账户金额
export function getBalanceByCurrency(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/getBalanceByCurrency',
    method: 'POST',
    data,
  })
}

// 更改或解绑邮箱
export function updateEmail(data, loading) {
  return service({
    updateUserInfo: true,
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/email/updateEmail',
    method: 'POST',
    data,
  })
}

// 更改或解绑手机
export function updateMobile(data, loading) {
  return service({
    updateUserInfo: true,
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/sms/updateMobile',
    method: 'POST',
    data,
  })
}

// （用户）禁用账户
export function disableUser(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/disableUser',
    method: 'POST',
    data,
  })
}

// 查看用户通知列表
export function getSysNoticeList(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/getSysNoticeList',
    method: 'POST',
    data,
  })
}

// 批量修改用户通知
export function updateSysNoticeList(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/updateSysNoticeList',
    method: 'POST',
    data,
  })
}

// 查看资金账户资产列表
export function getDigitalAssetList(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/getDigitalAssetList',
    method: 'POST',
    data,
  })
}

// 现货账户划转进资金账户
export function transferAccount(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/transferAccount',
    method: 'POST',
    data,
  })
}

// 查看提币额度配置列表
export function getConfigurationWithdrawalLimitList(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/getConfigurationWithdrawalLimitList',
    method: 'POST',
    data,
  })
}

// 用户修改提币白名单状态
export function updateWhiteListStatus(data, loading) {
  return service({
    updateUserInfo: true,
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/updateWhiteListStatus',
    method: 'POST',
    data,
  })
}

// 查看用户提币白名单列表
export function getSysWithdrawalWhiteListList(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/getSysWithdrawalWhiteListList',
    method: 'POST',
    data,
  })
}

// 修改用户提币白名单状态
export function updateSysWithdrawalWhiteListStatus(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/updateSysWithdrawalWhiteListStatus',
    method: 'POST',
    data,
  })
}

// 创建用户提币白名单
export function createSysWithdrawalWhiteList(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/createSysWithdrawalWhiteList',
    method: 'POST',
    data,
  })
}

// 保存用户钱包地址
export function setWalletAddress(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/setWalletAddress',
    method: 'POST',
    data,
  })
}

// 检查用户是否存在
export function checkUser(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: 'https://samoa.hk-wallet.com/v1/checkUser',
    method: 'POST',
    data,
  })
}

// 用户查看资金账户资产列表
// // 更改或解绑谷歌
// export function unbindCode(data, loading) {
//   return service({
//     loading: loading == false ? loading : true,
//     url: 'https://samoa.hk-wallet.com/v1/google/unbindCode',
//     method: 'POST',
//     data,
//   })
// }

// 获取当前货币24小时涨幅趋势
// export function getBinance24hr(data, loading) {
//   return service({
//     loading: loading == false ? loading : true,
//     url: 'https://samoa.hk-wallet.com/v1/getBinance24hr',
//     method: 'POST',
//     data
//   })
// }

// 根据货币获取对应市场24小时涨幅趋势
// export function getBinance24hrBySymbol(data, loading) {
//   return service({
//     loading: loading == false ? loading : true,
//     url: '/api/getBinance24hrBySymbol',
//     method: 'POST',
//     data
//   })
// }

// 24hr 价格变动情况
export function getBinance24hr(data, loading) {
  // console.log(data.symbolList)
  if(data.symbolList) {
    // var newSymbolList =   data.symbolList
    var newSymbolList =   encodeURI(JSON.stringify(data.symbolList))
  }
  if(data.symbol) {
    var symbol = data.symbol
  }
  return service({
    loading: loading == false ? loading : true,
    url: `/api/v3/ticker/24hr${symbol ? `?symbol=${symbol}` : ''}${newSymbolList && newSymbolList ? `?symbols=${newSymbolList}` : ''}`,
    method: 'GET',
  })
}

// k线图获取行情  
// export function pricingCharts(data, loading) {
//   return service({
//     loading: loading == false ? loading : true,
//     url: `/pricing/charts/?symbol=${data.symbol}&period=${data.period}`,
//     method: 'GET',
//     chartsShow: true
//   })
// }

// 币安k线图获取行情  
export function pricingCharts(data, loading) {
  return service({
    loading: loading == false ? loading : true,
    url: `/api/v3/uiKlines?symbol=${data.symbol}&interval=${data.period}`,
    method: 'GET',
    chartsShow: true
  })
}
