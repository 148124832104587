import {
  Message,
  Button,
  Link,
  Table,
  TableColumn,
  Tabs,
  TabPane,
  Form,
  FormItem,
  Input,
  Checkbox,
  Pagination,
  Select,
  Option,
  Loading,
  Dialog,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Popover,
  MessageBox,
  Steps, 
  Step,
  Progress,
  Carousel,
  CarouselItem,
  DatePicker,
  Upload,
  RadioGroup,
  Radio,
  Slider,
  Drawer,
  Descriptions,
  DescriptionsItem,
  Collapse,
  CollapseItem,
  Row,
} from 'element-ui'
const element = {
  install: function(Vue) {
    Vue.use(Button)
    Vue.use(Link)
    Vue.use(Table)
    Vue.use(TableColumn)
    Vue.use(Tabs)
    Vue.use(TabPane)
    Vue.use(Form)
    Vue.use(FormItem)
    Vue.use(Input)
    Vue.use(Checkbox)
    Vue.use(Pagination)
    Vue.use(Select)
    Vue.use(Option)
    Vue.use(Loading)
    Vue.use(Dialog)
    Vue.use(Dropdown)
    Vue.use(DropdownItem)
    Vue.use(DropdownMenu)
    Vue.use(Popover)
    Vue.use(Steps)
    Vue.use(Step)
    Vue.use(Progress)
    Vue.use(Carousel)
    Vue.use(CarouselItem)
    Vue.use(DatePicker)
    Vue.use(Upload)
    Vue.use(RadioGroup)
    Vue.use(Radio)
    Vue.use(Slider)
    Vue.use(Drawer)
    Vue.use(Descriptions)
    Vue.use(DescriptionsItem)
    Vue.use(Collapse)
    Vue.use(CollapseItem)
    Vue.use(Row)
      Vue.prototype.$message = Message;
      Vue.prototype.$confirm = MessageBox.confirm;
  }
}

export default element