import Vue from 'vue'
import VueRouter from 'vue-router'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
import Home from '@/views/home/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
  },
  {
    path: '/oceaniaCurrency',
    name: 'oceaniaCurrency',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '@/views/oceaniaCurrency/oceaniaCurrency.vue'
      ),
  },
  {
    path: '/buyCurrency',
    name: 'buyCurrency',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '@/views/buyCurrency/buyCurrency.vue'
      ),
  },
  {
    path: '/inform',
    name: 'inform',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/inform/inform.vue'),
  },
  {
    path: '/register',
    name: 'register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/register/register.vue'),
  },
  {
    path: '/condition',
    name: 'condition',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/condition/condition.vue'),
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/login/login.vue'),
  },
  {
    path: '/deal',
    name: 'deal',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/deal/deal.vue'),
  },
  {
    path: '/my',
    name: 'my',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '@/views/my/my-center/my-center.vue'
      ),
  },
  {
    path: '/accountActivity',
    name: 'accountActivity',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '@/views/my/account-activity/account-activity.vue'
      ),
  },
  {
    path: '/equipment',
    name: 'equipment',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '@/views/my/equipment/equipment.vue'
      ),
  },
  {
    path: '/accountSecurity',
    name: 'accountSecurity',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '@/views/my/account-security/account-security.vue'
      ),
  },
  {
    path: '/whiteList',
    name: 'whiteList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '@/views/my/white-list/white-list.vue'
      ),
  },
  {
    path: '/otc',
    name: 'Otc',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '@/views/OTC/OTC.vue'
      ),
  },
  {
    path: '/cryptoAtm',
    name: 'CryptoAtm',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '@/views/cryptoAtm/crypto-atm.vue'
      ),
  },
  {
    path: '/enterprise',
    name: 'enterprise',
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/KYC/enterprise.vue'),
    redirect: '/enterprise/overview',
    children: [
      {
        path: '/enterprise/overview',
        name: 'overview',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/views/KYC/enterprise/overview.vue'
          ),
      },
      {
        path: '/enterprise/verifyForm',
        name: 'verifyForm',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/views/KYC/enterprise/verify-form.vue'
          ),
      },
      {
        path: '/enterprise/basicInformation',
        name: 'basicInformation',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/views/KYC/enterprise/basic-information.vue'
          ),
      },
      {
        path: '/enterprise/uploadFiles',
        name: 'uploadFiles',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/views/KYC/enterprise/upload-files.vue'
          ),
      },
      {
        path: '/enterprise/organization',
        name: 'organization',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/views/KYC/enterprise/organization.vue'
          ),
      },
      {
        path: '/enterprise/verifyOk',
        name: 'verifyOk',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/views/KYC/enterprise/components/verify-ok.vue'
          ),
      },
    ],
  },
  {
    path: '/personal',
    name: 'personal',
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/KYC/personal.vue'),
    redirect: '/personal/information',
    children: [
      {
        path: '/personal/information',
        name: 'information',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/views/KYC/personal/information.vue'
          ),
      },
      {
        path: '/personal/identity',
        name: 'identity',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/views/KYC/personal/identity.vue'
          ),
      },
      {
        path: '/personal/verifyOk',
        name: 'verifyOk2',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/views/KYC/enterprise/components/verify-ok.vue'
          ),
      },
    ],
  },
  {
    path: '/forgetPassword',
    name: 'forgetPassword',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '@/views/forgetPassword/forget-password.vue'
      ),
  },
  {
    path: '/wallet',
    name: 'wallet',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '@/views/wallet/wallet.vue'
      ),
  },
  {
    path: '/history',
    name: 'history',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '@/views/wallet/history.vue'
      ),
  },
  {
    path: '/recharge',
    name: 'recharge',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '@/views/DIGICCY/recharge.vue'
      ),
  },
  {
    path: '/withdraw',
    name: 'withdraw',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '@/views/DIGICCY/withdraw.vue'
      ),
  },
  {
    path: '/withdraw2',
    name: 'withdraw2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '@/views/DIGICCY/withdraw2.vue'
      ),
  },
  {
    path: '/withDrawSuccess',
    name: 'withDrawSuccess',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '@/views/DIGICCY/withDrawSuccess.vue'
      ),
  },
]

const router = new VueRouter({
  routes,
})

export default router
