<template>
  <div id="app">
    <navigation-bar class="navigation" />
    <router-view :key="$store.state.language" class="content" />
		<loading v-if="loadingProps.spinning" />
    <footer-bar />
    <new-agreement-dialog v-if="agreementInfo.isShowDialog" :isShowDialog.sync="agreementInfo.isShowDialog" :title.sync="agreementInfo.title" @showDialog="setAgreementInfo({ title: '', isShowDialog: false  })"></new-agreement-dialog>
  </div>
</template>
<script>
import navigationBar from '@/components/navigation-bar/navigation-bar.vue'
import footerBar from '@/components/footer/footer.vue'
import Vue from 'vue'
import NewAgreementDialog from '@/components/agreement-dialog/agreement-dialog.vue'
import { mapState, mapMutations } from 'vuex'
export default {
  components: {
    navigationBar,
    footerBar,
    NewAgreementDialog
  },
  data() {
    return {
			loadingProps: {
				spinning: false,
			},
  }
},
computed: {
  ...mapState(['agreementInfo']),
},
created() {
},
methods: {
  ...mapMutations(['setAgreementInfo'])
},
	beforeCreate() { 
        //  将当前页面组件给到$app
		Vue.prototype.$app = this;
	}
}
</script>
<style lang="less" scoped>
.content {
  padding-top: 0.64rem;
}
</style>
