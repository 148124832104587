<template>
  <div class="common_home_background">
    <div class="home_content">
      <div class="head">
        <!-- <p class="bank">{{ $t('home.bank') }}</p> -->
        <!-- <div style="position: relative;">
          <img src="@/assets/Group 13@2x.png" alt="" />
          <img class="logo" src="@/assets/Group 10@2x.png" alt="" />
        </div> -->
        <p class="purchase">Nuru Bubble Exchange</p>
        <p class="authentication">便捷安全的数字货币交易平台</p>
        <h3>Nuru Bubble平台是一家综合数字货币交易服务提供商，<br>
致力于为用户提供安全、便捷的数字资产交易和管理解决方案。
我们的服务涵盖了加密货币ATM、钱包APP、场外交易（OTC）和数字货币交易所。</h3>
        <router-link v-if="!userInfo.token" class="register" to="/register">{{
          '现在开始'
        }}</router-link>
        <!-- <div class="right-border"></div> -->
        <div class="right-swiper">
          <el-carousel height="4.64rem">
            <el-carousel-item>
              <img
                style="width: 3.58rem; height: 4.64rem;"
                src="@/assets/Group 22.png"
                alt=""
              />
            </el-carousel-item>
            <el-carousel-item>
              <img
                style="width: 2.09rem; height: 4.18rem;"
                src="@/assets/Group 5.png"
                alt=""
              />
            </el-carousel-item>
            <el-carousel-item>
              <img
                style="width: 5.06rem; height: 3.48rem;"
                src="@/assets/23861694487371_.pic.jpg"
                alt=""
              />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="inform">
        <img src="@/assets/Shape@2x.png" alt="" />
        <el-carousel class="lunbo" height="0.35rem" direction="vertical">
          <el-carousel-item v-for="item in noticeList" :key="item.noticeId">
            <p>{{ item.title }}</p>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="percentage_data">
        <div class="percentage" v-for="item in List" :key="item.id">
          <div class="title">
            <p class="name">{{ item.symbol }}</p>
            <p
              class="percent"
              :style="item.priceChangePercent < 0 ? 'color: #D13232;' : ''"
            >
              {{ item.priceChangePercent + '%' }}
            </p>
          </div>
          <p class="value">
            {{ '$' + priceDispose(parseFloat(item.highPrice)) }}
          </p>
          <p class="price">{{ priceDispose(parseFloat(item.volume)) + 'M' }}</p>
        </div>
      </div>
      <div class="step">
        <div class="title">
          轻松进入加密世界，简单几步开始交易
        </div>
        <div class="steps-head">
          <el-steps :active="active" finish-status="finish">
            <el-step></el-step>
            <el-step></el-step>
            <el-step></el-step>
            <el-step></el-step>
          </el-steps>
        </div>

        <div class="steps-content">
          <div class="item">
            <img v-show="active >= 1" src="@/assets/Rectangle.png" alt="" />
            <img v-show="active < 1" src="@/assets/Rectangle (1).png" alt="" />
            <p>
              创建NuruBubble账号<br />
              手机号码注册，即可开始交易加密货币
            </p>
          </div>
          <div class="item">
            <img v-show="active >= 2" src="@/assets/Rectangle.png" alt="" />
            <img v-show="active < 2" src="@/assets/Rectangle (1).png" alt="" />
            <p>
              完成身份验证<br />
              在交易前需要完成身份验证，确保您的账户和交易安全
            </p>
          </div>
          <div class="item">
            <img v-show="active >= 3" src="@/assets/Rectangle.png" alt="" />
            <img v-show="active < 3" src="@/assets/Rectangle (1).png" alt="" />
            <p>
              账户充值<br />
              充值加密货币账户，我们提供多种方式，ATM现金买入、钱包充值、OTC
            </p>
          </div>
          <div class="item">
            <img v-show="active >= 4" src="@/assets/Rectangle.png" alt="" />
            <img v-show="active < 4" src="@/assets/Rectangle (1).png" alt="" />
            <p>
              开始交易<br />
              一切准备就绪，买卖加密货币，探索Nuru Bubble 更多的产品和服务
            </p>
          </div>
        </div>
      </div>
      <!-- <div class="guide">
        <p class="title">{{ $t('home.guide') }}</p>
        <p class="begin">{{ $t('home.guideBegin') }}</p>
        <div class="guide_img_content">
          <div class="guide_img">
            <img src="@/assets/Group 5 Copy@2x.png" alt="" />
            <p class="img_guide">{{ $t('home.understand') }}</p>
          </div>
          <div class="guide_img">
            <img src="@/assets/Group 5 Copy 2@2x.png" alt="" />
            <p class="img_guide">{{ $t('home.howBuy') }}</p>
          </div>
          <div class="guide_img">
            <img src="@/assets/Group 5@2x.png" alt="" />
            <p class="img_guide">{{ $t('home.sell') }}</p>
          </div>
        </div>
      </div> -->
      <div class="guide">
        <p class="title">{{ $t('home.trend') }}</p>
        <p class="begin" style="margin-bottom: 0.16rem;">
          {{ $t('home.presentTrend') }}
        </p>
        <div class="trend_table">
          <el-table :data="tableData" style="width: 90%;">
            <el-table-column :label="$t('home.name')">
              <template slot-scope="scope">
                <div class="moneyName">
                  <!-- <img class="table_img" :src="scope.row.img" alt="" /> -->
                  <span class="table_text">{{ scope.row.symbol }}</span>
                  <!-- <span class="table_name">{{ scope.row.name }}</span> -->
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('home.newest')">
              <template slot-scope="scope">
                <span class="table_price">{{
                  '$' + priceDispose(parseFloat(scope.row.highPrice))
                }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('home.moneyVariation')">
              <template slot-scope="scope">
                <span
                  class="table_percent"
                  :style="
                    scope.row.priceChangePercent < 0 ? 'color: #D13232;' : ''
                  "
                  >{{ scope.row.priceChangePercent + '%' }}</span
                >
              </template>
            </el-table-column>
            <el-table-column>
              <template slot-scope="scope">
                <div class="table_btn">
                  <el-button @click="handleEdit(scope.$index, scope.row)">{{
                    $t('home.buy')
                  }}</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!-- <p
            class="more"
            style="cursor: pointer;"
            @click="$router.push('/condition')"
          >
            {{ $t('home.viewMore') }}
          </p> -->
        </div>
      </div>
      <div class="one-deal">
        <img style="width: 100%; height: 5rem; margin-bottom: .2rem;" src="@/assets/23861694487371_.pic.jpg" alt="">
        <el-button
          class="btn"
          type="primary"
          @click="
            userInfo.token ? $router.push('/deal') : $router.push('/register')
          "
          >{{
            userInfo.token ? $t('head.transaction') : $t('home.register')
          }}</el-button>
      </div>
      <div class="regards">
        <h1>关于 Nuru Bubble</h1>
<p>我们的使命是打造一个可信赖、高效、创新的数字货币交易生态系统，为用户提供全方位的数字资产管理和交易服务。
平台采取了一系列严格的安全措施，保护用户的数字资产安全：<br>
多重认证机制，确保用户身份安全<br>
冷钱包存储，隔离网络攻击风险<br>
实时风险监控，保障交易安全</p>
      </div>
      <div class="deal">
        <p class="title">{{ $t('home.beginDeal') }}</p>
        <p class="begin">{{ $t('home.beginDealTravel') }}</p>
        <div class="img_list">
          <div class="list_box">
            <img src="@/assets/Group 4@2x.png" alt="" />
            <p class="list_title">{{ $t('home.serviceCharge') }}</p>
            <p class="list_text">{{ $t('home.introduce1') }}</p>
          </div>
          <div class="list_box" style="margin-right: 0.12rem;">
            <img src="@/assets/Group 17@2x.png" alt="" />
            <p class="list_title">{{ $t('home.rate') }}</p>
            <p class="list_text">{{ $t('home.introduce2') }}</p>
          </div>
          <div class="list_box">
            <img
              style="width: 0.31rem;"
              src="@/assets/Group 18@2x.png"
              alt=""
            />
            <p class="list_title">{{ $t('home.guarantee') }}</p>
            <p class="list_text">{{ $t('home.introduce3') }}</p>
          </div>
          <div class="list_box">
            <img src="@/assets/Group 20@2x.png" alt="" />
            <p class="list_title">{{ $t('home.customerService') }}</p>
            <p class="list_text">{{ $t('home.introduce4') }}</p>
          </div>
        </div>
        <el-button
          class="btn"
          type="primary"
          @click="
            userInfo.token ? $router.push('/deal') : $router.push('/register')
          "
          >{{
            userInfo.token ? $t('home.beginDeal') : $t('home.register')
          }}</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { priceDispose } from '@/common/validation'
import { mapState } from 'vuex'
import { getBinance24hr, getNoticeList } from '@/api/api'
// import axios from 'axios'
export default {
  data() {
    return {
      priceDispose,
      active: 0,
      activeTimer: null,
      List: [
        // {
        //   id: 1,
        //   name: 'BNB/BUSD',
        //   percentage: '+9.69%',
        //   value: '396.5',
        //   price: '$396.5',
        // },
      ],
      tableData: [
        // {
        //   img: require('@/assets/Oval 2@2x.png'),
        //   value: 'BTC',
        //   name: 'Bitcoin',
        //   price: '$44,171.07',
        //   percent: '+1.69%',
        // },
      ],
      noticeList: [], // 公告列表
    }
  },

  computed: {
    ...mapState(['userInfo', 'language']),
  },
  created() {
    this.getBinance24hr()
    this.getNoticeList()
    this.stepActive()
  },
  beforeDestroy() {
    if (this.activeTimer) {
      clearInterval(this.activeTimer);
    }
  },
  methods: {
    stepActive() {
      this.activeTimer = setInterval(() => {
        if(this.active !== 4) {
          this.active++
        } else {
          this.active = 0
        }
      }, 3000)
    },
    handleEdit(index, row) {
      this.$router.push('/deal')
    },
    async getNoticeList() {
      const res = await getNoticeList({})
      if(res.data.length !== 0) {
        res.data.forEach((item) => {
        if (this.language === 'zh-CN' && item.language == 0) {
          this.noticeList.push(item)
        } else if (this.language === 'tw' && item.language == 1) {
          this.noticeList.push(item)
        } else if (this.language === 'en' && item.language == 2) {
          this.noticeList.push(item)
        }
      })
      }
    },
    async getBinance24hr() {
      const res = await getBinance24hr({})
      if (typeof res == 'object') {
        let filteredData = res.filter((item) => item.symbol.includes('USDT') && item.highPrice !== '0.00000000')
        this.List = filteredData.slice(1, 4)
        this.tableData = filteredData.slice(1, 9)
        // console.log(filteredData);
        //   res.forEach((item, index) => {
        //   if(index <= 2) {
        //     this.List.push(item)
        //     this.tableData.push(item)
        //   }
        //   if(index == 3) {
        //     this.tableData.push(item)
        //   }
        // })
      }
    },
  },
}
</script>

<style src="./home.css" scoped></style>
