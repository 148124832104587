import router from "./index";
import store from "../store/index"

// 导航守卫
router.beforeEach((to, from, next) => {
  store.commit('setAll')
  window,scrollTo(0,0);
  const token = store.state.userInfo.token
  const url = to.path
  const whiteList = ['/', '/home', '/buyCurrency', '/condition', '/oceaniaCurrency', '/register', '/login', '/forgetPassword', '/otc', '/cryptoAtm', '/deal'] 
  if(token && (url === '/login' || url === '/register')){
      next('/')
      return
  }
  
  // 未登录拦截
  if(!token && !whiteList.includes(url)){
    next('/login')
    // store.commit('setLoginShow',true)
    return
  }
  next()
})